import { GridColDef } from '@material-ui/data-grid';

const defaultColumnConfig: Partial<GridColDef> = {
  editable: false,
};

const createColumn = (
  field: string,
  headerName: string,
  width: number,
  align: 'center' | 'left' | 'right',
  headerAlign: 'center' | 'left' | 'right',
) => ({
  field,
  headerName,
  width,
  align,
  headerAlign,
  ...defaultColumnConfig,
});

export const columns: GridColDef[] = [
  createColumn('cod_integracao', 'Código', 200, 'center', 'center'),
  createColumn('des_integracao', 'Integração', 550, 'left', 'left'),
  createColumn('des_lojas', 'Lojas', 150, 'left', 'left'),
  createColumn(
    'dta_ult_requisicao',
    'Última Requisição',
    250,
    'right',
    'right',
  ),
];
