/* eslint-disable no-console */
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import FormDefault from '~/components/FormDefault';
import { InputNumber } from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';

import useDebounce from '~/hooks/useDebounce';
import { CustomFooter } from './components/CustomFooter';
import { columns } from './constants/columns';
import { formMonitorBlank, LIMIT_REGISTER_PER_PAGE } from './constants/monitor';
import { FormMonitor, SaveSettings, Tokens } from './protocols/monitor';
import monitorApi from './services/api';
import {
  ButtonAtt,
  Container,
  InputNumberContainer,
  TableContainer,
} from './styles';
import { schemaMonitorApi } from './validations';

export const MonitorApi: React.FC = () => {
  const [formMonitor, setFormMonitor] = useState<FormMonitor>(formMonitorBlank);
  const [tokens, setTokens] = useState<Tokens[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [page, setPage] = useState(1);
  const [counter, setCounter] = useState(0);

  const { debouncedFn } = useDebounce();

  const { register, control, setValue, watch, getValues, clearErrors } =
    useForm({
      resolver: yupResolver(schemaMonitorApi),
      reValidateMode: 'onChange',
    });

  const handleSwitchAcessoApi = useCallback(() => {
    setFormMonitor((prev) => ({
      ...prev,
      flg_api_integracao_ativo: !prev.flg_api_integracao_ativo,
    }));
    setCounter(1);
  }, []);

  const getTokens = useCallback(async () => {
    const tokensdata = await monitorApi.getTokens();

    const data = tokensdata.data.map((token) => ({
      id: token.cod_integracao,
      ...token,
    }));

    return setTokens(tokensdata.success ? data : []);
  }, []);

  const getSettings = useCallback(async () => {
    const settings = await monitorApi.getSettings();

    if (settings.success) {
      const { flg_api_integracao_ativo, num_api_integracao_freq_atu } =
        settings.data;

      setFormMonitor((prev) => ({
        ...prev,
        flg_api_integracao_ativo,
      }));

      setValue(
        'num_api_integracao_freq_atu',
        num_api_integracao_freq_atu < 30 ? 30 : num_api_integracao_freq_atu,
      );
    }
  }, [setValue]);

  const updateFrequency =
    parseInt(watch('num_api_integracao_freq_atu'), 10) ||
    parseInt(getValues('num_api_integracao_freq_atu'), 10);

  const checkFrequency = updateFrequency < 30 ? 30 : updateFrequency;

  const frequency = parseInt(checkFrequency.toString().slice(0, 3), 10);

  const { refetch } = useQuery('tokens', getTokens, {
    enabled: formMonitor.flg_api_integracao_ativo,
    refetchInterval: Number.isNaN(updateFrequency) ? 1000 : frequency * 1000,
  });

  const handleRefreshToken = useCallback(async () => {
    setCounter(1);
    setIsLoading(true);
    await refetch();
    setIsLoading(false);
  }, [refetch]);

  const saveSettings = useCallback(async () => {
    if (!isMounted || Number.isNaN(updateFrequency) || counter === 0) return;

    if (frequency < 0 || frequency > 300) {
      const slice = updateFrequency.toString().slice(0, 2);
      return toast.warning(
        `Valor de frequência inválido, foi registrado apenas ${slice} segundos`,
      );
    }

    const defaultFrequency = 30;

    const frequencyToSave =
      Number.isNaN(updateFrequency) || updateFrequency < defaultFrequency
        ? defaultFrequency
        : updateFrequency;

    const data: SaveSettings = {
      flg_api_integracao_ativo: formMonitor.flg_api_integracao_ativo,
      num_api_integracao_freq_atu: frequencyToSave,
    };

    debouncedFn(async () => {
      const res = await monitorApi.saveSettings(data);
      if (res.success && counter === 1) return toast.success(res.message);
      setCounter(0);
    }, 1100);

    if (counter === 0) setCounter(1);
  }, [
    counter,
    debouncedFn,
    formMonitor.flg_api_integracao_ativo,
    frequency,
    isMounted,
    updateFrequency,
  ]);

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  useEffect(() => {
    if (isMounted && counter !== 0) saveSettings();
  }, [
    formMonitor.flg_api_integracao_ativo,
    updateFrequency,
    isMounted,
    counter,
  ]);

  useEffect(() => {
    if (isMounted) {
      clearErrors('num_api_integracao_freq_atu');
      getSettings();
      refetch();
    }
  }, [clearErrors, getSettings, isMounted, refetch]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Container>
      <FormDefault
        codTela={282}
        title="MONITOR DE API DE INTEGRAÇÃO"
        onSave={() => []}
        onCancel={() => []}
        onClearFields={() => []}
        onNew={() => []}
        onDelete={() => []}
        onReturnSearch={() => []}
        isDelete={false}
        isClear={false}
        isSave
        isNew={false}
        isCancel={false}
        hideFooter
      >
        <Row
          style={{ position: 'relative' }}
          className="mt-2 d-flex justify-content-between"
        >
          <Col sm={12} md={2} lg={4} xl={4} className="align-self-end">
            <Separator labelText="Acesso" />
            <ToggleDefault
              labelText="Habilitado acesso a API?"
              setChecked={formMonitor.flg_api_integracao_ativo}
              inLine
              onSwitch={handleSwitchAcessoApi}
              labelOnRight
              disabled={isLoading}
            />
          </Col>
          <Col style={{ marginTop: '0.625rem' }} sm={12} md={4} lg={2} xl={2}>
            <InputNumberContainer>
              <InputNumber
                label="Frequência de atualização"
                max={300}
                min={30}
                step="30"
                maxLength={3}
                placeholder="0"
                name="num_api_integracao_freq_atu"
                register={register}
                control={control}
                disabled={isLoading}
                isError={
                  isMounted
                    ? Number.isNaN(updateFrequency) ||
                      frequency < 30 ||
                      frequency > 300
                    : false
                }
                onInput={(ev: ChangeEvent<HTMLInputElement>) => {
                  const { value } = ev.target;

                  if (value < '30') ev.target.value = '30';

                  setValue(
                    'num_api_integracao_freq_atu',
                    value < '30' ? '30' : value,
                  );

                  setCounter(1);
                }}
              />

              <span
                style={{ position: 'absolute', right: '-4.5rem', top: 30 }}
                className="seconds"
              >
                segundo(s)
              </span>
            </InputNumberContainer>
          </Col>
          <Col style={{ marginTop: '2.5rem' }} sm={12} md={4} lg={3} xl={3}>
            <ButtonAtt
              style={{ padding: '0.5rem', position: 'absolute', right: 15 }}
              type="button"
              onClick={handleRefreshToken}
            >
              {isLoading && (
                <CircularProgress style={{ color: '#fff' }} size={22} />
              )}
              {!isLoading && <>Atualizar</>}
            </ButtonAtt>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableContainer>
              <div style={{ height: '30rem' }}>
                <DataGrid
                  className="DataGrid"
                  rows={tokens}
                  columns={columns}
                  page={page - 1}
                  rowCount={tokens.length}
                  onPageChange={handlePageChange}
                  pageSize={LIMIT_REGISTER_PER_PAGE}
                  disableColumnMenu
                  hideFooter
                  hideFooterPagination
                  localeText={{
                    noRowsLabel: 'Nenhum registro encontrado...',
                    columnMenuLabel: 'Menu',
                    columnMenuFilter: 'Filtrar',
                    columnMenuHideColumn: 'Esconder',
                    columnMenuUnsort: 'Não ordenar',
                    columnMenuSortAsc: 'Ordernar ASC',
                    columnMenuSortDesc: 'Ordernar DESC',
                    columnMenuShowColumns: 'Mostrar colunas',
                  }}
                />
              </div>
            </TableContainer>
          </Col>
        </Row>
      </FormDefault>
      <CustomFooter
        rowsCount={tokens.length}
        currentPage={page}
        onPageChange={handlePageChange}
      />
    </Container>
  );
};
