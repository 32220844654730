import { Select } from './Select';

export interface FormMonitor {
  flg_recepcao_xml_ativo: boolean;
  tipo_recepcao_xml_per_log: Select;
  num_recepcao_xml_freq_atu: Select;
}

export interface Parametros {
  cod_log: number;
  dta_inicio: string;
  tipo: number;
}

export const formMonitorBlank: FormMonitor = {
  flg_recepcao_xml_ativo: false,
  tipo_recepcao_xml_per_log: { value: 0, label: '' },
  num_recepcao_xml_freq_atu: { value: 0, label: '' },
};
