import api from '~/services/api';
import {
  Response,
  SaveSettings,
  SettingsResponse,
  TokensResponse,
} from '../protocols/monitor';

const monitorApi = {
  getTokens: async (): Promise<TokensResponse> => {
    const { data } = await api.get<TokensResponse>(
      '/monitor-api-integracao/tokens',
    );
    return data;
  },
  saveSettings: async (form: SaveSettings): Promise<Response> => {
    const { data } = await api.put<Response>('/monitor-api-integracao', form);
    const { success, message } = data;
    return { success, message };
  },
  getSettings: async (): Promise<SettingsResponse> => {
    const { data } = await api.get<SettingsResponse>('/monitor-api-integracao');
    return data;
  },
};

export default monitorApi;
