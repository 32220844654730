import React, { useCallback, useEffect, useState, useContext } from 'react';
import { DataGrid, GridColDef } from '@material-ui/data-grid';

import { Container, TableContainer, RefreshButton } from './styles';
import api from '~/services/api';

import { errorHandler } from '~/utils/ErrorHandler';

import { toast } from 'react-toastify';
import Loja from '~/components/Loja';

import {
  SelectLoja,
  tipoLogs,
  Select,
  FormMonitor,
  formMonitorBlank,
  Log,
  Parametros,
  frequencias,
} from './protocols';
import { Alert, Col, Row } from 'react-bootstrap';
import ToggleDefault from '~/components/ToggleDefault';
import InputSelect from '~/components/Inputs/InputSelect';

import { useQuery } from 'react-query';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import FormDefault from '~/components/FormDefault';
import { LojaContext } from '~/context/loja';
import Pagination from '~/components/Pagination';
import Separator from '~/components/Separator';

interface CustomFooterProps {
  refetch: () => void;
  isLoading: boolean;
}

const MonitorXml: React.FC = () => {
  const [lojas, setLojas] = useState<number[]>([]);
  const [formMonitor, setFormMonitor] = useState<FormMonitor>(formMonitorBlank);
  const [logs, setLogs] = useState<Log[]>([]);
  const { codLoja } = useContext(LojaContext);
  const [oldPage, setOldPage] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [parametros, setParametros] = useState<Parametros>({
    cod_log: 0,
    dta_inicio: moment().format('YYYY-MM-DD HH:mm:ss'),
    tipo: 0,
  });
  const handleLoadNewPage = useCallback(
    async (newPage: number) => {
      setOldPage(page);
      setPage(newPage);
    },
    [page],
  );
  const CustomFooter: React.FC<CustomFooterProps> = ({ refetch }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClickRefresh = async () => {
      setIsLoading(true);
      await refetch();
      setIsLoading(false);
    };

    return (
      <div className="d-flex flex-align-center justify-content-end p-2">
        <Pagination
          rowsCount={logs.length}
          tela={61}
          currentPage={page}
          onPageChange={handleLoadNewPage}
          hasButtonUpdate={false}
        />
        <RefreshButton
          onClick={handleClickRefresh}
          className="d-flex flex-align-center justify-content-center"
          style={{
            minWidth: '70px',
            maxHeigth: '35px',
            marginTop: '10px',
          }}
        >
          {isLoading ? (
            <CircularProgress style={{ color: '#fff' }} size={22} />
          ) : (
            <>Atualizar</>
          )}
        </RefreshButton>
      </div>
    );
  };

  const teste: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
      align: 'center',
      field: 'cod_log',
      headerName: 'Seq',
      width: 90,
      editable: false,
    },
    {
      align: 'right',
      field: 'cod_loja',
      headerName: 'Loja',
      width: 90,
      editable: false,
    },
    {
      field: 'des_fornecedor',
      headerName: 'Fornecedor',
      width: 200,
      editable: false,
      align: 'center',
    },
    {
      field: 'cod_xml',
      headerName: 'Código XML',
      width: 140,
      editable: false,
      align: 'right',
    },
    {
      align: 'right',
      field: 'num_chave_acesso',
      headerName: 'Chave de Acesso',
      width: 200,
      editable: false,
    },
    {
      align: 'right',
      field: 'num_nf',
      headerName: 'Num. NFe',
      width: 130,
      editable: false,
    },
    {
      field: 'num_serie_nf',
      headerName: 'Série',
      width: 90,
      align: 'right',
    },
    {
      field: 'motivo',
      headerName: 'Log',
      width: 130,
      minWidth: 130,
      align: 'center',
    },
    {
      field: 'dta_cadastro',
      headerName: 'Data',
      width: 170,
      align: 'center',
    },
  ];

  const getLogs = async () => {
    try {
      const res = await api.get('/monitor-xml/logs', {
        params: {
          tipo: formMonitor.tipo_recepcao_xml_per_log.value,
          cod_log: parametros.cod_log,
          dta_inicio: parametros.dta_inicio,
          lojas,
        },
      });
      const { success, data } = res.data;
      const regs = data.map((log: Log) => {
        return {
          id: log.cod_log,
          cod_log: log.cod_log,
          cod_loja: log.cod_loja,
          cod_xml: log.cod_xml,
          dta_cadastro: log.dta_cadastro,
          motivo: log.motivo,
          num_chave_acesso: log.num_chave_acesso,
          num_nf: log.num_nf,
          num_serie_nf: log.num_serie_nf,
          des_fornecedor: log.des_fornecedor,
        };
      });

      if (formMonitor.flg_recepcao_xml_ativo) {
        const lastItem = regs[0];
        if (lastItem) {
          setParametros({
            ...parametros,
            cod_log: lastItem.cod_log,
            tipo: Number(formMonitor.tipo_recepcao_xml_per_log.value),
          });
        }
        if (formMonitor.tipo_recepcao_xml_per_log.value !== parametros.tipo) {
          return setLogs(regs);
        }
        return setLogs([...regs, ...logs]);
      }

      return setLogs(regs);
    } catch (error: any) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    const getLojas = async () => {
      try {
        const res = await api.get('/monitor-xml/lojas');
        const { data, success, message } = res.data;
        if (!success) return toast.warning(message);
        const arrLojas = data
          .filter((loja: SelectLoja) => loja.flg_recepcao_xml_ativo)
          .map((loja: SelectLoja) => loja.cod_loja);
        if (arrLojas.lenght > 0) {
          setLojas(arrLojas);
        } else {
          setLojas([codLoja]);
        }
      } catch (error: any) {
        errorHandler(error);
      }
    };

    getLojas();

    const getConfig = async () => {
      try {
        const res = await api.get('/monitor-xml');
        const { data, success, message } = res.data;
        if (!success) return toast.warning(message);
        setFormMonitor({
          flg_recepcao_xml_ativo: data.flg_recepcao_xml_ativo,
          num_recepcao_xml_freq_atu: {
            label: '',
            value: data.num_recepcao_xml_freq_atu,
          },
          tipo_recepcao_xml_per_log: {
            label: '',
            value: data.tipo_recepcao_xml_per_log,
          },
        });
      } catch (error: any) {
        errorHandler(error);
      }
    };

    getConfig();
  }, []);

  useEffect(() => {
    if (lojas.length > 0) {
      getLogs();
    }
  }, [lojas, formMonitor]);

  useEffect(() => {
    if (formMonitor.flg_recepcao_xml_ativo) {
      setParametros((p) => {
        return {
          ...p,
          tipo: Number(formMonitor.tipo_recepcao_xml_per_log.value),
          cod_log: 0,
        };
      });
    }
  }, [
    formMonitor.tipo_recepcao_xml_per_log.value,
    formMonitor.flg_recepcao_xml_ativo,
  ]);

  const salvaConfig = useCallback(async () => {
    try {
      const form = {
        flg_recepcao_xml_ativo: formMonitor.flg_recepcao_xml_ativo,
        tipo_recepcao_xml_per_log: formMonitor.tipo_recepcao_xml_per_log.value,
        num_recepcao_xml_freq_atu: formMonitor.num_recepcao_xml_freq_atu.value,
      };
      const res = await api.put('/monitor-xml', form);
      const { success, message } = res.data;
      if (!success) return toast.warning(message);
    } catch (error: any) {
      errorHandler(error);
    }
  }, [formMonitor]);

  useEffect(() => {
    salvaConfig();
  }, [
    formMonitor.flg_recepcao_xml_ativo,
    formMonitor.tipo_recepcao_xml_per_log.value,
    salvaConfig,
  ]);

  const changeLojas = useCallback(async (e) => {
    try {
      if (e.length === 0) {
        setLojas([codLoja]);
      } else {
        setLojas(e);
      }
      const update = e.map((loja: any) => {
        return {
          cod_loja: loja.cod_loja,
          flg_recepcao_xml_ativo: loja.flg_checked,
        };
      });
      const res = await api.put('/monitor-xml/lojas', {
        itens: update,
      });
      const { success, message } = res.data;
      // setLojas(e);
      if (!success) return toast.warning(message);
    } catch (error: any) {
      errorHandler(error);
    }
  }, []);

  const handleSwitchRecepcao = () => {
    if (lojas.length === 0) {
      return toast.warning('Selecione ao menos uma loja para continuar.');
    }
    setFormMonitor({
      ...formMonitor,
      flg_recepcao_xml_ativo: !formMonitor.flg_recepcao_xml_ativo,
    });
    setParametros({
      ...parametros,
      cod_log: 0,
      dta_inicio: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    if (!formMonitor.flg_recepcao_xml_ativo) {
      setLogs([]);
    }
  };

  const { data, error, refetch } = useQuery('logs', getLogs, {
    enabled: formMonitor.flg_recepcao_xml_ativo,
    // refetchInterval: Number(formMonitor.num_recepcao_xml_freq_atu.value) * 1000,
    refetchInterval: 5 * 1000,
    // keepPreviousData: true,
  });

  return (
    <Container>
      <FormDefault
        codTela={61}
        title="Monitor de Recepção de XMLs"
        onSave={() => []}
        onCancel={() => []}
        onClearFields={() => []}
        onNew={() => []}
        onDelete={() => []}
        onReturnSearch={() => []}
        isDelete={false}
        isClear={false}
        isSave
        isNew={false}
        isCancel={false}
      >
        <Row>
          <Col sm={12} md={12} lg={12} xl={12}>
            <Loja
              selectedLoja={lojas}
              isMulti
              onChange={changeLojas}
              disabled={formMonitor.flg_recepcao_xml_ativo}
            />
          </Col>
        </Row>

        <Row className="mt-2 d-flex justify-content-between">
          <Col sm={12} md={4} lg={4} xl={4} className="align-self-end">
            <Separator labelText="Serviço" marginTop="5px" />
            <ToggleDefault
              labelText="Recepção Automática de XMLs"
              setChecked={formMonitor.flg_recepcao_xml_ativo}
              inLine
              onSwitch={handleSwitchRecepcao}
            />
          </Col>
          <Col sm={12} md={4} lg={2} xl={2}>
            <InputSelect
              label="Frequência de Atualização"
              value={frequencias.find((item: Select) => {
                return (
                  item.value === formMonitor.num_recepcao_xml_freq_atu.value
                );
              })}
              options={frequencias}
              // isDisabled={formMonitor.flg_recepcao_xml_ativo}
              onChange={(e) =>
                setFormMonitor({
                  ...formMonitor,
                  num_recepcao_xml_freq_atu: e,
                })
              }
            />
          </Col>
          <Col sm={12} md={4} lg={3} xl={3}>
            <InputSelect
              label="Exibir Logs"
              value={tipoLogs.find((item: Select) => {
                return (
                  item.value === formMonitor.tipo_recepcao_xml_per_log.value
                );
              })}
              options={tipoLogs}
              // isDisabled={formMonitor.flg_recepcao_xml_ativo}
              onChange={(e) =>
                setFormMonitor({
                  ...formMonitor,
                  tipo_recepcao_xml_per_log: e,
                })
              }
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <TableContainer>
              <div style={{ height: '550px' }}>
                <DataGrid
                  className="DataGrid"
                  rows={logs}
                  columns={teste}
                  page={page - 1}
                  rowCount={logs.length}
                  onPageChange={handleLoadNewPage}
                  pageSize={8}
                  disableColumnMenu
                  components={{ Footer: CustomFooter }}
                  componentsProps={{
                    footer: { refetch },
                  }}
                  localeText={{
                    noRowsLabel: 'Nenhum registro encontrado...',
                    columnMenuLabel: 'Menu',
                    columnMenuFilter: 'Filtrar',
                    columnMenuHideColumn: 'Esconder',
                    columnMenuUnsort: 'Não ordenar',
                    columnMenuSortAsc: 'Ordernar ASC',
                    columnMenuSortDesc: 'Ordernar DESC',
                    columnMenuShowColumns: 'Mostrar colunas',
                  }}
                />
              </div>
            </TableContainer>
          </Col>
        </Row>
        <Alert variant="warning" className="mt-5">
          <strong>Atenção</strong>: Se deseja visualizar logs retroativos acesse
          a tela &quot;Relatório de Logs de XMLs&ldquo;
        </Alert>
      </FormDefault>
    </Container>
  );
};

export default MonitorXml;
