import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

export const TableContainer = styled.div`
  height: 100%;
  height: 500px;
  margin-top: 1rem;
`;

export const RefreshButton = styled(Button)`
  background-color: #57069e;
  color: #fff;
  border: none;
  max-height: 35px;

  &:hover,
  &:active,
  &:focus {
    border: none;
    outline: none;
    background-color: #8850bf;
    box-shadow: none !important;
  }
`;
