import { Select } from './Select';

export const tipoLogs: Select[] = [
  {
    label: 'Última hora',
    value: 0,
  },
  {
    label: '6 horas',
    value: 1,
  },
  {
    label: '12 horas',
    value: 2,
  },
  {
    label: 'Último dia',
    value: 3,
  },
];

export interface Log {
  cod_log: number;
  cod_loja: number | undefined;
  cod_xml: number;
  dta_cadastro: string;
  motivo: string;
  num_chave_acesso: string;
  num_nf: number;
  num_serie_nf: string;
  des_fornecedor: string;
}
