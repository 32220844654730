import React from 'react';
import Pagination from '~/components/Pagination';

import { LIMIT_REGISTER_PER_PAGE } from '../../constants/monitor';

interface CustomFooterProps {
  rowsCount: number;
  currentPage?: number;
  onPageChange: (page: number) => void;
}

export const CustomFooter: React.FC<CustomFooterProps> = ({
  rowsCount,
  currentPage = 1,
  onPageChange,
}) => {
  return (
    <div className="d-flex flex-align-center justify-content-end p-2">
      <Pagination
        rowsCount={rowsCount}
        tela={282}
        currentPage={currentPage}
        onPageChange={onPageChange}
        hasButtonUpdate={false}
        limit={LIMIT_REGISTER_PER_PAGE}
      />
    </div>
  );
};
