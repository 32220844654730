import { Select } from './Select';

export const frequencias: Select[] = [
  {
    label: '30 segundos',
    value: 30,
  },
  {
    label: '1 minuto',
    value: 60,
  },
  {
    label: '3 minutos',
    value: 180,
  },
  {
    label: '5 minutos',
    value: 300,
  },
];
