import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: 100%;
  padding: 1.25rem;
`;

export const InputNumberContainer = styled.div`
  position: relative;

  span.seconds {
    @media screen and (max-width: 74.9375rem) {
      margin-top: 0.9375rem;
    }

    @media screen and (max-width: 61.875rem) {
      margin-top: 0;
    }

    @media screen and (max-width: 47.9375rem) {
      position: absolute;
      left: 0 !important;
      margin-top: 2.1875rem;
    }
  }
`;

export const ButtonAtt = styled.button`
  border: 0;
  background-color: #57069e;
  border-radius: 0.3125rem;
  color: white;
  font-size: medium;

  &:hover {
    background-color: #8850bf;
  }

  @media screen and (max-width: 47.9375rem) {
    bottom: -0.625rem !important;
  }
`;

export const TableContainer = styled.div`
  height: 100%;
  height: 30rem;
  margin-top: 1rem;

  @media screen and (max-width: 47.9375rem) {
    margin-top: 1.25rem;
  }
`;
