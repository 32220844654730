import React from 'react';
import { Tooltip } from '@material-ui/core';
import { GridColumns } from '@material-ui/data-grid';
import { withStyles } from '@material-ui/core/styles';

interface CustomGridColumns {
  field: string;
  headerName: string;
  width: number;
  minWidth?: number;
  align?: string;
  fixed?: boolean;
  sortable?: boolean;
  disableColumnMenu?: boolean;
  renderCell?: (row: any) => void;
  fixedDirection?: string;
  hide?: boolean;
  flex?: number;
}

// export const freteColumns: CustomGridColumns[] = [

const CustomToolTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#57069e',
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export const columns: CustomGridColumns[] = [
  {
    field: 'id',
    headerName: 'Id',
    hide: true,
    width: 80,
    sortable: false,
    disableColumnMenu: true,
    align: 'center',
  },
  {
    field: 'cod_log',
    headerName: 'Seq',
    width: 80,
    sortable: false,
    disableColumnMenu: true,
    align: 'right',
  },
  {
    field: 'cod_loja',
    headerName: 'Loja',
    width: 80,
    sortable: false,
    disableColumnMenu: true,
    align: 'right',
  },
  {
    field: 'des_fornecedor',
    headerName: 'Fornecedor',
    width: 130,
    sortable: false,
    align: 'center',
    disableColumnMenu: true,
    renderCell: (params: any) => {
      return (
        <CustomToolTip
          title={params.des_fornecedor}
          placement="bottom-start"
          className="text-truncate"
        >
          <span>{params.des_fornecedor}</span>
        </CustomToolTip>
      );
    },
  },
  {
    align: 'right',
    field: 'cod_xml',
    headerName: 'Código XML',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'num_chave_acesso',
    headerName: 'Chave de Acesso',
    width: 200,
    sortable: false,
    disableColumnMenu: true,
    align: 'right',
    renderCell: (params: any) => {
      return (
        <CustomToolTip
          title={params.num_chave_acesso}
          placement="bottom-start"
          className="text-truncate"
        >
          <span>{params.num_chave_acesso}</span>
        </CustomToolTip>
      );
    },
  },
  {
    field: 'num_nf',
    headerName: 'Num. NFe',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    hide: false,
    align: 'right',
  },
  {
    field: 'num_serie_nf',
    headerName: 'Série',
    width: 80,
    sortable: false,
    disableColumnMenu: true,
    align: 'right',
  },
  {
    field: 'motivo',
    headerName: 'Log',
    // flex: 1,
    width: 130,
    minWidth: 130,
    align: 'center',
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      return (
        <CustomToolTip
          title={params.motivo}
          placement="bottom-start"
          className="text-truncate"
        >
          <span>{params.motivo}</span>
        </CustomToolTip>
      );
    },
  },
  {
    field: 'dta_cadastro',
    headerName: 'Data',
    width: 170,
    sortable: false,
    align: 'center',
    disableColumnMenu: true,
  },
];
